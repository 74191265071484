<template>
  <v-form ref="formElem">
    <v-card>
      <v-card-text>
        <v-autocomplete
          v-model="form.distributor_id"
          :items="distributorOptions"
          item-text="label"
          item-value="value"
          label="Select Distributor"
          outlined
          hide-details
        ></v-autocomplete>

        <v-text-field
          v-model="form.notes"
          label="Notes"
          outlined
          class="mt-5"
        />
      </v-card-text>

      <v-card-actions class="justify-center">
        <v-btn
          color="primary"
          :loading="loading"
          @click="validate"
        >
          Submit
        </v-btn>
      </v-card-actions>

      <v-alert
        v-for="msg in errors"
        :key="msg"
        color="warning"
      >
        {{ msg }}
      </v-alert>
    </v-card>
  </v-form>
</template>

<script>
import { useDistributor, useSortTitle } from '@/composables';
import channelStore from '@/modules/channel/store';
import { required } from '@core/utils/validation';
import { ref } from '@vue/composition-api';

export default {
  components: {
  },

  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    disabledFields: {
      type: Array,
      default() {
        return []
      },
    },
    errors: {
      type: Array,
      default() {
        return []
      },
    },
    resource: {
      // default resource
      type: Object,
      required: true,
    },
  },

  watch: {
    'form.channel_id': 'listDistributors',
  },

  setup(props, { emit }) {
    // Get value from resource prop, use initValue if not exist
    const field = (name, initValue) => ({
      [name]: props.resource ? props.resource[name] : initValue,
    })

    const rules = props.resource ? props.resource.rules : false

    const channelOptions = channelStore.state.channels
      .map(channel => ({ title: `${channel.code}`, value: channel.id }))
      .sort(useSortTitle)

    const initialForm = {
      ...field('distributor_id', null),
      ...field('notes', null),
    }

    const form = ref({
      ...initialForm,
    })
    const formElem = ref(null)

    const { distributorOptions, fetchDistributors } = useDistributor()

    const listDistributors = () => fetchDistributors({
      channel_id: props.resource.channel_id,
      take: 100,
    })

    listDistributors()

    const validate = () => {
      if (formElem.value.validate()) emit('submit', form.value)
    }

    return {
      form,
      formElem,
      validate,
      required,
      channelOptions,
      distributorOptions,
      listDistributors,
    }
  },
}
</script>
